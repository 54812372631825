import React from "react";
import { FaBriefcase } from "react-icons/fa";

function ExperienceWeb() {
  const experiences = [
    {
      title: "Systems Engineer",
      company: "Tata Consultancy Services",
      location: "Pune, India",
      duration: "July 2021 - Jan 2024",
      description:
        "At TCS, I enhanced and maintained critical airline systems by applying modern technologies such as Node.js, AWS, JEST, Serverless, and REST API development. I successfully implemented crucial services, including Biometrics, Notifications, and Profiles, using Serverless architecture, resulting in a 60% increase in user engagement. Additionally, I developed and optimized Boarding Pass generation, REST APIs, and Akamai mapping to ensure seamless content delivery. To improve system performance and maintain code quality, I integrated Kafka, SonarQube, and Kibana into the workflow.",
    },
    {
      title: "Technology Development Intern",
      company: "Triluxo Technologies",
      location: "Remote",
      duration: "Oct 2020 - Dec 2020",
      description:
        "During my internship at Triluxo Technologies, I engineered a feature-rich chatbot using Node.js and Google Dialogflow for hotel room service management. This enabled guests to effortlessly request services like food ordering and room cleaning. I developed a robust Node.js backend to facilitate communication between the chatbot and the hotel’s service system. Leveraging Google Dialogflow, I added advanced natural language processing features and collaborated with front-end developers to ensure a cohesive and user-friendly experience.",
    },
    {
      title: "Software Developer Intern",
      company: "Gallant Fusion Tech",
      location: "Remote",
      duration: "July 2020 - Sep 2020",
      description:
        "At Gallant Fusion Tech, I architected and implemented the backend and database infrastructure for a golf game application using Node.js, Express.js, and MySQL. I designed a scalable and optimized system to ensure seamless functionality for the app. By incorporating passport-jwt for endpoint validation, I enhanced the application’s security. Additionally, I collaborated closely with the team to conceptualize and develop efficient server-side solutions tailored for a robust user experience.",
    },
  ];

  return (
    <section
      id="experience-web"
      className="mb-16 relative">
      <div className="text-center">
        <h2 className="text-4xl font-bold mb-16 text-[#13FF00] inline-block border-b border-l border-r border-[#13FF00] px-4">
          Experience
        </h2>
      </div>

      {/* Central Vertical Line */}
      <div className="absolute left-1/2 transform -translate-x-1/2 h-[93%] w-[2px] bg-[#13FF00]"></div>

      {/* Experience Items */}
      <div className="relative max-w-5xl mx-auto flex flex-col space-y-16">
        {experiences.map((exp, index) => (
          <div
            key={index}
            className={`relative flex ${
              index % 2 === 0 ? "justify-start" : "justify-end"
            }`}>
            {/* Node */}
            <div className="absolute left-1/2 transform -translate-x-1/2 w-8 h-8 rounded-full bg-[#13FF00]"></div>

            {/* Horizontal Line */}
            <div
              className={`absolute left-1/2 transform -translate-x-1/8 h-[2px] bg-[#13FF00] ${
                index % 2 === 0
                  ? "translate-y-[0px] w-[calc(50%-2rem)] -translate-x-[100%]"
                  : "w-[calc(50%-2rem)]"
              }`}></div>

            {/* Experience Box */}
            <div
              className={`w-5/12 p-6 bg-[#3F3F3F] rounded-lg shadow-lg border-2 border-[#13FF00] ${
                index % 2 === 0 ? "text-left ml-6" : "text-right mr-6"
              }`}>
              <div className="flex items-center mb-4">
                <FaBriefcase className="text-[#13FF00] mr-4 text-2xl" />
                <div>
                  <h3 className="text-2xl font-bold">{exp.title}</h3>
                  <p className="text-[#13FF00]">{exp.company}</p>
                  <p>
                    {exp.location} | {exp.duration}
                  </p>
                </div>
              </div>
              <p className="text-gray-300 text-justify">{exp.description}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default ExperienceWeb;
