import React, { useState, useEffect } from "react";
import {
  FaGithub,
  FaLinkedin,
  FaEnvelope,
  FaGlobe,
  FaWhatsapp,
  FaMedium,
} from "react-icons/fa";

function Hero() {
  const titles = [
    "Software Engineer",
    "Web Developer",
    "Back-End Developer",
    "Tech Enthusiast",
  ];
  const [currentTitle, setCurrentTitle] = useState(0);
  const [animationClass, setAnimationClass] = useState("");

  useEffect(() => {
    const interval = setInterval(() => {
      // Trigger slide-out animation
      setAnimationClass("opacity-0 -translate-y-4");
      setTimeout(() => {
        // Update title and trigger slide-in animation
        setCurrentTitle((prev) => (prev + 1) % titles.length);
        setAnimationClass("opacity-100 translate-y-0");
      }, 500); // Match duration of slide-out animation
    }, 3000); // Total duration before changing titles

    return () => clearInterval(interval); // Cleanup on component unmount
  }, [titles.length]);

  return (
    <div className="min-h-screen relative flex items-center">
      {/* Green Circle Animation */}
      <div className="absolute right-[-200px] top-[-200px] w-[900px] h-[900px] border-[#13FF00] border-2 rounded-full">
        <div className="absolute inset-0 blur-lg bg-[#13FF00] opacity-20 rounded-full"></div>
      </div>

      {/* Content */}
      <div className="container mx-auto px-4 flex flex-col md:flex-row items-center justify-between relative z-10">
        <div className="md:w-1/2 space-y-6">
          <h2 className="text-xl md:text-2xl text-gray-300">Hello world!</h2>
          <h1 className="text-5xl md:text-7xl font-bold">
            <span className="text-[#13FF00] block mb-2">Priyanshu Dubey</span>
          </h1>
          <div className="relative overflow-hidden h-10">
            <h3
              className={`absolute top-0 text-3xl md:text-4xl font-semibold text-gray-200 transition-all duration-500 ease-in-out ${animationClass}`}>
              {titles[currentTitle]}
            </h3>
          </div>
          <p className="text-gray-400 max-w-md">
            Passionate Software Engineer with over 3 years of experience in
            designing and building scalable solutions in cloud and back-end
            development. Proficient in Node.js, AWS, and serverless
            architectures.
            <br />
            Currently I am studying Master's in
            <strong> Advance Computer Science</strong> from Swansea University.
          </p>
          <div className="flex space-x-4 pt-4">
            <a
              href="https://drive.google.com/file/d/1kaSAUFixvZdDSwU3uswYaSA5E_5Ro3Uq/view?usp=sharing"
              target="_blank"
              rel="noopener noreferrer"
              className="px-8 py-3 bg-[#13FF00] rounded-md text-black hover:bg-[#0FCC00] transition-colors duration-300">
              View Resume
            </a>
          </div>
          {/* Social Links */}
          <div className="flex space-x-6 pt-6 text-2xl">
            <a
              href="https://github.com/priyanshudubey"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-300 hover:text-[#13FF00] transition-colors duration-300">
              <FaGithub />
            </a>
            <a
              href="https://linkedin.com/in/priyanshudubey/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-300 hover:text-[#13FF00] transition-colors duration-300">
              <FaLinkedin />
            </a>
            <a
              href="mailto:priyanshu0dubey@gmail.com"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-300 hover:text-[#13FF00] transition-colors duration-300">
              <FaEnvelope />
            </a>
            <a
              href="https://priyanshudubey.com"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-300 hover:text-[#13FF00] transition-colors duration-300">
              <FaGlobe />
            </a>
            <a
              href="http://wa.me/+447444764677"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-300 hover:text-[#13FF00] transition-colors duration-300">
              <FaWhatsapp />
            </a>
            <a
              href="https://medium.com/@priyanshu0dubey"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-300 hover:text-[#13FF00] transition-colors duration-300">
              <FaMedium />
            </a>
          </div>
        </div>

        {/* Image */}
        <div className="md:w-1/3 mt-8 md:mt-18">
          <div className="relative w-full max-w-lg mx-auto">
            <div className="inset-0 z-10"></div>
            {/* <img
              src="/MyImage.png?height=600&width=500"
              alt="Professional headshot"
              className="w-full h-auto rounded-lg object-cover"
            /> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
