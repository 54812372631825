import React from "react";
import { FaGraduationCap } from "react-icons/fa";

function Education() {
  const education = [
    {
      degree: "Master of Science - M.Sc",
      institution: "Swansea University",
      duration: "Expected 2025",
      details: "Advance Computer Science.",
    },
    {
      degree: "Bachelor of Technology",
      institution: "Sagar Institute Of Science and Technology",
      duration: "2017 - 2021",
      details: "Computer Science and Technology.",
    },
  ];

  return (
    <section
      id="education"
      className="mb-16 animate-fade-in relative">
      <div className="text-center">
        <h2 className="text-4xl font-bold mb-16 text-[#13FF00] inline-block border-b border-l border-r border-[#13FF00] px-4">
          Education
        </h2>
      </div>
      {education.map((edu, index) => (
        <div
          key={index}
          className="bg-[#3F3F3F] p-8 rounded-lg shadow-lg mb-8 border-2 border-[#13FF00]">
          <div className="flex items-center mb-4 ">
            <FaGraduationCap className="text-[#13FF00] mr-4 text-2xl" />
            <div>
              <h3 className="text-2xl font-bold">{edu.degree}</h3>
              <p className="text-[#13FF00]">{edu.institution}</p>
              <p>{edu.duration}</p>
            </div>
          </div>
          <p>{edu.details}</p>
        </div>
      ))}
    </section>
  );
}

export default Education;
