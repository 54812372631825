import React from "react";
import { FaHeart } from "react-icons/fa";

function Footer() {
  return (
    <footer className="bg-[#3F3F3F] py-6">
      <div className="container mx-auto px-4 text-center">
        <p className="flex items-center justify-center">
          Made with <FaHeart className="text-[#13FF00] mx-2" /> by
          <span className="text-[#13FF00] ml-2">Priyanshu Dubey</span>
        </p>
        <p className="mt-2">
          &copy; {new Date().getFullYear()} All rights reserved.
        </p>
      </div>
    </footer>
  );
}

export default Footer;
