import React, { useState } from "react";
import { Link } from "react-scroll";
import { HiMenu } from "react-icons/hi";

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <nav className="fixed w-full z-50 top-0 left-0 p-5 bg-[#202020]">
      <div className="container mx-auto flex justify-between items-center">
        <a
          href="https://priyanshudubey.com"
          className="text-[#13FF00] text-3xl font-bold">
          pd..
        </a>

        <div className="hidden md:flex space-x-8">
          <Link
            to="home"
            smooth={true}
            duration={500}
            offset={-70}
            className="hover:text-[#13FF00] cursor-pointer transition-colors">
            Home
          </Link>
          <Link
            to="education"
            smooth={true}
            duration={500}
            offset={-70}
            className="hover:text-[#13FF00] cursor-pointer transition-colors">
            Education
          </Link>
          <Link
            to="experience"
            smooth={true}
            duration={500}
            offset={-70}
            className="hover:text-[#13FF00] cursor-pointer transition-colors">
            Experience
          </Link>
          <Link
            to="projects"
            smooth={true}
            duration={500}
            offset={-70}
            className="hover:text-[#13FF00] cursor-pointer transition-colors">
            Projects
          </Link>
          <Link
            to="techstack"
            smooth={true}
            duration={500}
            offset={-70}
            className="hover:text-[#13FF00] cursor-pointer transition-colors">
            Technologies
          </Link>
        </div>

        <div className="flex items-center space-x-4">
          <button
            className="md:hidden text-2xl"
            onClick={() => setIsOpen(!isOpen)}>
            <HiMenu />
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      {isOpen && (
        <div className="md:hidden absolute top-full left-0 w-full bg-[#202020] border-t border-gray-800">
          <div className="flex flex-col p-4 space-y-4">
            <Link
              to="home"
              smooth={true}
              duration={500}
              offset={-70}
              className="hover:text-[#13FF00] cursor-pointer transition-colors">
              Home
            </Link>
            <Link
              to="education"
              smooth={true}
              duration={500}
              offset={-70}
              className="hover:text-[#13FF00] cursor-pointer transition-colors">
              Education
            </Link>
            <Link
              to="experience"
              smooth={true}
              duration={500}
              offset={-70}
              className="hover:text-[#13FF00] cursor-pointer transition-colors">
              Experience
            </Link>
            <Link
              to="projects"
              smooth={true}
              duration={500}
              offset={-70}
              className="hover:text-[#13FF00] cursor-pointer transition-colors">
              Projects
            </Link>
            <Link
              to="techstack"
              smooth={true}
              duration={500}
              offset={-70}
              className="hover:text-[#13FF00] cursor-pointer transition-colors">
              Technologies
            </Link>
          </div>
        </div>
      )}
    </nav>
  );
}

export default Navbar;
