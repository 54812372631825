import React from "react";
import {
  FaJs,
  FaPython,
  FaNodeJs,
  FaAws,
  FaDatabase,
  FaReact,
  FaHtml5,
  FaCss3,
} from "react-icons/fa";
import { SiServerless, SiExpress, SiGit } from "react-icons/si";

function TechStack() {
  const skills = [
    { name: "JavaScript", icon: <FaJs /> },
    { name: "Python", icon: <FaPython /> },
    { name: "Node.js", icon: <FaNodeJs /> },
    { name: "AWS", icon: <FaAws /> },
    { name: "MySQL", icon: <FaDatabase /> },
    { name: "React.js", icon: <FaReact /> },
    {
      name: "HTML/CSS",
      icon: (
        <>
          <FaHtml5 />
          <FaCss3 />
        </>
      ),
    },
    { name: "Serverless", icon: <SiServerless /> },
    { name: "Express.js", icon: <SiExpress /> },
    { name: "GIT", icon: <SiGit /> },
  ];

  return (
    <section
      id="tech-stack"
      className="mb-16 animate-fade-in relative">
      <div className="text-center">
        <h2 className="text-4xl font-bold mb-16 text-[#13FF00] inline-block border-b border-l border-r border-[#13FF00] px-4">
          Technology Stack
        </h2>
      </div>
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-8">
        {skills.map((skill, index) => (
          <div
            key={index}
            className="bg-[#3F3F3F] p-4 rounded-lg shadow-lg flex flex-col items-center justify-center hover:bg-[#13FF00] hover:text-[#202020] transition-all duration-300">
            <div className="text-4xl mb-2">{skill.icon}</div>
            <p className="text-center">{skill.name}</p>
          </div>
        ))}
      </div>
    </section>
  );
}

export default TechStack;
