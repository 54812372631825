import React from "react";
import { FaGithub } from "react-icons/fa";

function ProjectsWeb() {
  const projects = [
    {
      title: "Resume Builder Project",
      description:
        "A full-stack application that allows users to create, customize, and download ATS-friendly resumes. Built with React.js, Node.js, Express, and MongoDB, it provides real-time preview, drag-and-drop features, and seamless PDF generation.",
      technologies: ["React.js", "Node.js", "Express", "MongoDB", "PDF.js"],
      githubLink: "https://github.com/priyanshudubey/resume-builder",
    },
    {
      title: "Email Template System",
      description:
        "A dynamic system for creating, editing, and executing email templates. Features include placeholder rendering, live preview, and email sending using React.js, Node.js, MySQL, and Mustache.js.",
      technologies: ["React.js", "Node.js", "MySQL", "Mustache.js"],
      githubLink: "https://github.com/priyanshudubey/email-template-system",
    },
  ];

  return (
    <section
      id="projects-web"
      className="mb-16 relative">
      <div className="text-center">
        <h2 className="text-4xl font-bold mb-16 text-[#13FF00] inline-block border-b border-l border-r border-[#13FF00] px-4">
          Projects
        </h2>
      </div>

      {/* Central Vertical Line */}
      <div className="absolute left-1/2 transform -translate-x-1/2 h-[87%] w-[2px] bg-[#13FF00]"></div>

      {/* Project Items */}
      <div className="relative max-w-5xl mx-auto flex flex-col space-y-16">
        {projects.map((project, index) => (
          <div
            key={index}
            className={`relative flex ${
              index % 2 === 0 ? "justify-start" : "justify-end"
            }`}>
            {/* Node */}
            <div className="absolute left-1/2 transform -translate-x-1/2 w-8 h-8 rounded-full bg-[#13FF00]"></div>

            {/* Horizontal Line */}
            <div
              className={`absolute left-1/2 transform -translate-x-1/8 h-[2px] bg-[#13FF00] ${
                index % 2 === 0
                  ? "translate-y-[0px] w-[calc(50%-2rem)] -translate-x-[100%]"
                  : "w-[calc(50%-2rem)]"
              }`}></div>

            {/* Project Box */}
            <div
              className={`w-5/12 p-6 bg-[#3F3F3F] rounded-lg shadow-lg border-2 border-[#13FF00] ${
                index % 2 === 0 ? "text-left ml-6" : "text-right mr-6"
              }`}>
              <h3 className="text-2xl font-bold">{project.title}</h3>
              <p className="text-gray-300 mb-4 text-justify">
                {project.description}
              </p>
              <div className="flex flex-wrap gap-2 mb-4">
                {project.technologies.map((tech, i) => (
                  <span
                    key={i}
                    className="bg-[#13FF00] text-black px-2 py-1 rounded-md text-sm">
                    {tech}
                  </span>
                ))}
              </div>
              <a
                href={project.githubLink}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center text-gray-300 hover:text-[#13FF00] transition-colors duration-300">
                <FaGithub className="mr-2 text-xl" />
                GitHub Repository
              </a>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default ProjectsWeb;
